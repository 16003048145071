import React from "react";

export const MenuButton = ({ menuActive, setMenuActive }) => {
  const handleClick = () => {
    setMenuActive((prevState) => !prevState);
  };

  return (
    <button
      id="menuBtn"
      className={`hamburger hamburger--squeeze ${
        menuActive ? "is-active" : ""
      }`}
      aria-label="Navigation menu"
      type="button"
      onClick={handleClick}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
      <span className="hamburger-text">menu</span>
    </button>
  );
};

export default MenuButton;
