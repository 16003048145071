import React from "react";
import { Helmet } from "react-helmet";
import SiteFooter from "./SiteFooter";
import "../styles/main.scss";
import MenuContainer from "./Menu/MenuContainer";

// import then needed Font Awesome functionality
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faInstagram,
  faFacebookMessenger,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
// import {  } from '@fortawesome/free-regular-svg-icons';
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faAngleRight,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

// add the imported icons to the library
library.add(
  faFacebook,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faFacebookSquare,
  faInstagram,
  faFacebookMessenger,
  faAngleRight,
  faCheckCircle
);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Heebo%7COpen+Sans&amp;display=swap"
        />
      </Helmet>
      <MenuContainer />
      <div>{children}</div>
      <SiteFooter />
    </>
  );
}
