import React from "react";
import { Link } from "gatsby";

export const PrimaryNav = ({ menuActive, setHoverMenuContent }) => {
  const clearHoverImages = () => setHoverMenuContent(false);

  return (
    <nav
      id="primaryNavigation"
      className={`nav-primary ${menuActive ? "is-active" : ""}`}
    >
      <div className="menu-header-container">
        <ul id="menu-header" className="nav">
          <li
            id="menu-item-38"
            className="menu-item  menu-item-home menu-item-38"
          >
            <Link
              activeClassName="active"
              onMouseEnter={() => setHoverMenuContent("//")}
              onMouseLeave={clearHoverImages}
              to="/"
            >
              Home
            </Link>
          </li>
          <li id="menu-item-40" className="menu-item  menu-item-40">
            <Link
              activeClassName="active"
              onMouseEnter={() => setHoverMenuContent("/why-have-a-website/")}
              onMouseLeave={clearHoverImages}
              to="/why-have-a-website/"
            >
              Why have a website?
            </Link>
          </li>
          <li
            id="menu-item-56"
            className="menu-item  current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-56"
          >
            <Link
              activeClassName="active"
              onMouseEnter={() => setHoverMenuContent("/how-to-get-online/")}
              onMouseLeave={clearHoverImages}
              to="/how-to-get-online/"
            >
              How to get online?
            </Link>
            <ul className="sub-menu">
              <li id="menu-item-181" className="menu-item  menu-item-181">
                <Link
                  activeClassName="active"
                  onMouseEnter={() => setHoverMenuContent("/base/")}
                  onMouseLeave={clearHoverImages}
                  to="/base/"
                >
                  Base
                </Link>
              </li>
              <li id="menu-item-180" className="menu-item  menu-item-180">
                <Link
                  activeClassName="active"
                  onMouseEnter={() => setHoverMenuContent("/grow/")}
                  onMouseLeave={clearHoverImages}
                  to="/grow/"
                >
                  Grow
                </Link>
              </li>
              <li
                id="menu-item-179"
                className="menu-item  current-menu-item page_item page-item-157 current_page_item menu-item-179"
              >
                <Link
                  activeClassName="active"
                  onMouseEnter={() => setHoverMenuContent("/impress/")}
                  onMouseLeave={clearHoverImages}
                  to="/impress/"
                  aria-current="page"
                >
                  Impress
                </Link>
              </li>
            </ul>
          </li>
          <li id="menu-item-37" className="menu-item  menu-item-37">
            <Link
              activeClassName="active"
              onMouseEnter={() => setHoverMenuContent("/get-in-touch/")}
              onMouseLeave={clearHoverImages}
              to="/get-in-touch/"
            >
              Get in Touch
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default PrimaryNav;
