import React, { useState } from "react";
import SiteHeader from "../SiteHeader";
import PrimaryNav from "./PrimaryNav";
import NavContent from "./NavContent";

const stripTrailingSlash = (str) => {
  return str.endsWith("/") && str.length > 1 ? str.slice(0, -1) : str;
};

export const MenuContainer = () => {
  const [menuActive, setMenuActive] = useState(false);

  const pathName =
    typeof window !== "undefined" ? window.location.pathname : "/";

  const [menuContent, setMenuContent] = useState(
    stripTrailingSlash(pathName) + "/"
  );
  const [hoverMenuContent, setHoverMenuContent] = useState(false);

  return (
    <>
      <SiteHeader menuActive={menuActive} setMenuActive={setMenuActive} />
      <PrimaryNav
        setMenuContent={setMenuContent}
        setHoverMenuContent={setHoverMenuContent}
        menuActive={menuActive}
      />
      <NavContent
        menuContent={menuContent}
        hoverMenuContent={hoverMenuContent}
        menuActive={menuActive}
      />
    </>
  );
};

export default MenuContainer;
