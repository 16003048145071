import React from "react";
import { Link } from "gatsby";
import MenuButton from "./Menu/MenuButton";
import Headroom from "react-headroom";

export const SiteHeader = ({ menuActive, setMenuActive }) => {
  return (
    <Headroom disableInlineStyles>
      <header
        id="siteHeader"
        className={`site-header ${menuActive ? "is-active" : ""}`}
      >
        <Link className="brand d-block" to="/">
          <img src="/images/logo-white.svg" alt="Sixvees logo" />
          <img src="/images/logo-gradient.svg" alt="Sixvees logo gradient" />
        </Link>
        <MenuButton menuActive={menuActive} setMenuActive={setMenuActive} />
      </header>
    </Headroom>
  );
};

export default SiteHeader;
