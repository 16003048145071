import React from "react";

export const NavContent = ({ menuActive, menuContent, hoverMenuContent }) => {
  const NavContentImages = {
    "//": [
      { src: "/images/menu/home-gif.gif", alt: "Home" },
      {
        src: "/images/menu/sv2-575x431.jpg",
        alt: "Bike jump queenstown",
      },
    ],
    "/why-have-a-website/": [
      {
        src: "/images/menu/why.gif",
        alt: "Why",
      },
      {
        src: "/images/menu/charles-leqrylJNYUQ-unsplash-575x762.jpg",
        alt: "Google phone",
      },
    ],
    "/how-to-get-online/": [
      {
        src: "/images/menu/online-shop-575x863.jpg",
        alt: "Online shop",
      },
      { src: "/images/menu/how.gif", alt: "how" },
    ],
    "/base/": [
      {
        src: "/images/menu/thumbs-up.gif",
        alt: "Boy pulling thumbs up",
      },
      {
        src: "/images/menu/102031838_m-e1572223598501-575x833.jpg",
        alt: "Popsicles",
      },
    ],
    "/grow/": [
      {
        src: "/images/menu/ice-cream-575x863.jpg",
        alt: "Ice cream in cone.",
      },
      { src: "/images/menu/level-up.gif", alt: "level-up" },
    ],
    "/impress/": [
      {
        src: "/images/menu/phoebe-wow.gif",
        alt: "Phoebe from Friends saying wow",
      },
      {
        src: "/images/menu/104921455_m-e1572223560294-575x887.jpg",
        alt: "Extreme milkshake.",
      },
    ],
    "/get-in-touch/": [
      { src: "/images/menu/6v1-575x575.jpg", alt: "Bjarne and Chloe" },
      { src: "/images/menu/phone-call.gif", alt: "Phone call" },
    ],
  };

  const imageKey = hoverMenuContent ? hoverMenuContent : menuContent;

  return (
    <section id="navContent" className={`${menuActive ? "is-active" : ""}`}>
      {NavContentImages[imageKey] && (
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="d-flex col-lg-6 h-100 mt-4 mt-lg-0 align-items-center justify-content-center mx-auto">
              <div className="page-preview__image">
                <img
                  className="img-fluid"
                  src={NavContentImages[imageKey][0].src}
                  alt={NavContentImages[imageKey][0].alt}
                />
              </div>
            </div>
            <div className="d-none d-lg-flex col-lg-6 h-100 mt-4 mt-lg-0 align-items-center justify-content-center mx-auto">
              <div className="page-preview__image">
                <img
                  className="img-fluid"
                  src={NavContentImages[imageKey][1].src}
                  alt={NavContentImages[imageKey][1].alt}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default NavContent;
