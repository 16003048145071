import React from "react";
import { Link } from "gatsby";
import logo from "../images/footer-logo.png";

const SiteFooter = () => (
  <footer className="site-footer">
    <div className="container">
      <img src={logo} alt="Sixvees logo" className="img-fluid mb-5" />
      <ul className="list-inline">
        <li className="list-inline-item">
          <a href="mailto:hello@sixvees.co.nz" aria-label="Email address">
            <i className="fas fa-envelope"></i>
          </a>
        </li>
        <li className="list-inline-item">
          <a href="tel:0221523912" aria-label="Phone number">
            <i className="fas fa-phone fa-rotate-90"></i>
          </a>
        </li>
        <li className="list-inline-item">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/sixvees/"
            aria-label="Sixvees Instagram page"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </li>
        <li className="list-inline-item">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/sixvees/"
            aria-label="Sixvees Facebook page"
          >
            <i className="fab fa-facebook-square"></i>
          </a>
        </li>
        <li className="list-inline-item">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://m.me/sixvees"
            aria-label="Sixvees Facebook messenger"
          >
            <i className="fab fa-facebook-messenger"></i>
          </a>
        </li>
      </ul>
      <div className="row pt-4 pt-lg-5">
        <div className="col-xl-6">
          <nav id="footerNavigation" className="nav-primary">
            <div className="menu-footer-container">
              <ul
                id="menu-footer"
                className="mb-4 mb-xl-0 nav justify-content-center justify-content-xl-start"
              >
                <li className="menu-item">
                  <Link to="/why-have-a-website/" activeClassName="active">
                    Why have a website?
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/how-to-get-online/" activeClassName="active">
                    How to get online?
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to="/get-in-touch/"
                    activeClassName="active"
                    aria-current="page"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="col-xl-6 text-xl-right">
          <ul className="list-inline site-footer__contacts">
            <li className="list-inline-item">
              <a href="tel:0221523912" aria-label="Phone number">
                022 152 3912
              </a>
            </li>
            <li className="list-inline-item">
              <a href="mailto:hello@sixvees.co.nz" aria-label="Email address">
                hello@sixvees.co.nz
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default SiteFooter;
